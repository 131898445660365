import React from 'react'
import NavBar from 'src/components/NavBar'
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import ProjectPage from 'src/components/project/ProjectPage'
import GetInTouch from 'src/components/GetInTouch'
import Footer from 'src/components/Footer'
//@ts-ignore
import * as Styles from './project.module.scss'
const project = ({ data }: { data: any }) => {
    const intl = useIntl()
    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({ id: 'INKA Limited' })}</title>
            </Helmet>
            <div className={Styles.main}>
                <NavBar />
                <ProjectPage />
                <GetInTouch />
                <Footer />
            </div>
        </>
    )
}

export default project
