import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import ProjectHighlighDesktop from 'src/components/ProjectHighlighDesktop'
import ProjectList from './ProjectList'
//@ts-ignore
import * as Styles from './ProjectPage.module.scss'

const ProjectPage = () => {
    const breakpoints = useBreakpoint()
    return (
        <div  className={Styles.main}>
            {
                breakpoints.sm ?
                    <></> : <ProjectHighlighDesktop 
                        projectPage={true}
                    />
            }
            <ProjectList />
        </div>
    )
}

export default ProjectPage
