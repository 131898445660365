import React from 'react'
import { useIntl } from "gatsby-plugin-intl"
import ColumnLines from 'src/components/ColumnLines'
import Classnames from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as _ from 'lodash'
import { projects } from 'src/projectData.store'

// @ts-ignore
import * as Styles from './ProjectList.module.scss'
import YearBox from '../YearBox';

const ProjectList = () => {
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const projectLists = breakpoints.sm ? projects : projects.filter(item => !item.feature)
    return (
        <div lang={intl.locale} className={Styles.main}>
            {/* <ColumnLines /> */}
            <div className={Styles.content}>
                {
                    projectLists.map(item => <a
                        href={`/project-detail/?projectId=${item.projectId}`}
                        className={Styles.box}>
                        {<img
                            src={_.head(item.imsrc)}
                            className={Classnames({ [Styles.feature]: item.feature })}
                        />}
                        <div className={Styles.text}>
                            <h5>
                                {intl.formatMessage({ id: item.nameId })}
                                <YearBox period={intl.formatMessage({ id: item.periodId })} />
                            </h5>
                            <div className={Styles.detail}>
                                {intl.formatMessage({ id: item.workId })}
                            </div>
                        </div>
                    </a>)
                }
            </div>
        </div>
    )
}

export default ProjectList
